<template>
  <c-brand-page>
    <b-container fluid>
      <b-row>
        <b-col class="dialog">
          <c-set-new-password-card :rid="rid" />
        </b-col>
      </b-row>
    </b-container>
  </c-brand-page>
</template>

<script>
import cBrandPage from "@/components/c-brand-page";
import cSetNewPasswordCard from "@/components/c-set-new-password-card";

export default {
  components: {
    cSetNewPasswordCard,
    cBrandPage,
  },
  props: {
    rid: {
      required: true
    }
  }
};
</script>
