<template>
<c-brand-page>
<b-container fluid>
  <b-row>
    <b-col class="dialog">
        <c-password-reset-request-card />
    </b-col>
  </b-row>
</b-container>
</c-brand-page>
</template>

<script>
import cBrandPage from "@/components/c-brand-page";
import cPasswordResetRequestCard from '@/components/c-password-reset-request-card';

export default {
  components: {
    cPasswordResetRequestCard,
    cBrandPage,
  },
}
</script>

<style lang="scss">

</style>
