<template>
  <c-default-card :title="T('Reset Password')" :btnText="T('Submit')" @submit="submit">
    <b-form-group :label="T('Email')" label-for="reset-email">
      <b-form-input
        type="email"
        id="reset-email"
        v-model="v$.email.$model"
        :placeholder="T('Enter email')"
        :state="vuelidateState(v$.email)"
        :invalid-feedback="T(v$.email.$error[0])"
        @keyup.enter="submit"
      />
    </b-form-group>
  </c-default-card>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";

import cDefaultCard from "@/components/c-default-card";
import { vuelidateState } from "../lib/vuelidate-helper";

export default {
    setup: () => ({
      v$: useVuelidate(),
      vuelidateState
    }),
  components: {
    cDefaultCard,
  },
  data() {
    return {
      email: ""
    };
  },
  validations() {
      const localRules = {
          email: {required, email}
      }
      return localRules
  },
  methods: {
    async submit(evt) {
      evt.preventDefault();
      const isValidInput = await this.v$.$validate();
      if (isValidInput) {
        const _vm = this;
        this.$store.dispatch("submitPasswordResetRequest", this.email)
            .then(() => {
              _vm.notificationDialog(
                _vm.T('Check Your Email'),
                _vm.T('Reset instruction have been send to your inbox. if you have not recieved a password reset email, make sure to also check your Junk Mail folder.'),
                { okTitle: _vm.T('Ok') }
              ).then(() => {
                _vm.resetForm();
                _vm.$router.push({ name: 'login' });
              })
            });
      }
    },
    resetForm() {
      this.email = '';
      this.v$.$reset();
    },
  },
};
</script>

<style lang="scss" scoped>
 .default-card {
   min-width: 24rem;
   max-width: 50%;
 }
</style>
