<template>
<b-form-invalid-feedback class="feedback" :force-show="show">
  <div v-for="m in validation.$errors" v-bind:key="m.$uid">{{ T(m.$message) }}</div>
</b-form-invalid-feedback>
</template>

<script>
 export default {
     props: {
         validation: {
             type: Object,
             required: true,
         },
     },
     computed: {
         show() {
             return this.validation.$error;
         },
         messages() {
             return this.validation.$error.$message
         }
     },
 }
</script>

<style type="scss" scoped>
 .feedback {
   text-align: center;
   color: var(--danger);
   font-family: var(--font-brand-normal);
 }
</style>
