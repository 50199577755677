<template>
<div>
  <c-default-card v-if="true || isValidRequestID" :title="T('Set New Password')"
                  :btnText="T('Reset Password')"
                  @submit="onSubmit">


    <b-form-group :label="T('Enter New Password')" label-for="password">
      <b-form-input
        id="password"
        type="password"
        @keyup.enter="$refs.confirmPassword.focus()"
        v-model="password" />
    </b-form-group>

    <b-form-group :label="T('Re-enter Password')" label-for="confirmPassword">
      <b-form-input
        type="password"
        id="confirmPassword"
        refs="confirmPassword"
        @keyup.enter="onSubmit"
        v-model="v$.confirmPassword.$model"
        :state="v$.$dirty ? !v$.$invalid : null"
        />
    </b-form-group>
    <c-validation-error-feedback :validation="v$.confirmPassword" />
  </c-default-card>

  <b-alert v-else show variant="danger">
    <h4>{{ T('Expired password request link') }}</h4>
    <router-link to="/reset-password/request">
      {{ T('Request reset password link') }}
    </router-link>
  </b-alert>
</div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import useVuelidate from "@vuelidate/core";
import { required, sameAs, helpers } from "@vuelidate/validators";

import cDefaultCard from "@/components/c-default-card";
import cValidationErrorFeedback from "@/components/c-validation-error-feedback";

export default {
  setup: () => ({ v$: useVuelidate() }),
  components: {
    cDefaultCard,
    cValidationErrorFeedback
  },
  props: {
    rid: {
      required: true
    }
  },
  data() {
    return {
      password: "",
      confirmPassword: ""
    };
  },
  validations() {
      return {
          confirmPassword: {
              required,
              sameAsPassword: helpers.withMessage('Does not match password',
                                                  sameAs(this.password)),
          }
      }
  },
  computed: {
    ...mapGetters(["isValidRequestID"])
  },
  methods: {
    ...mapActions(["validateRequestID"]),
    resetForm() {
      this.password = '';
      this.confirmPassword = '';
      this.v$.$reset();
    },
    async onSubmit() {
      const isValidInput = await this.v$.$validate()
      if (isValidInput) {
        const _vm = this;
        this.$store.dispatch("resetPassword", {
          id: this.rid,
          password: this.password
        }).then(() => {
          _vm.notificationDialog(
            _vm.T('Success!'),
            _vm.T('Your password has been successfuly reset.'),
            { okTitle: _vm.T('Go to Login Page') }
          ).then(() => {
            _vm.resetForm();
            _vm.$router.push({name: 'login'});
          })
        });
      }
    }
  },
  created() {
    this.$store.dispatch("validateRequestID", this.rid);
  },
};
</script>

<style lang="scss" scoped>
 .default-card {
   min-width: 24rem;
   max-width: 50%;
 }

 .alert {
   margin: auto;
   max-width: 50%;
   text-align: center;
   a {
     color: inherit;
     filter: brightness(150%);
   }
 }
</style>
